const roleMapping: Record<string, string> = {}

const isAuthorized = (route: any, roles: any) => {
    const pageRole = Object.keys(roleMapping)
        .find(page => route.path.includes(page))
    if (pageRole === undefined) {
        return true
    }
    return roles?.[0]?.role?.includes(roleMapping[pageRole])
}

export default defineNuxtRouteMiddleware(async (_to, _from) => {
    if (_to.path === '/login' || _to.path === '/logout') {
        return
    }

    const user = useSupabaseUser()
    const supabase = useSupabaseClient<any>()
    const { isOnLoginFlow, splashIcon, splashText } = useSplashScreen()

    if (_to.hash.includes('#access_token')) {
        isOnLoginFlow.value = true
        splashIcon.value = '🔒'
        splashText.value = 'Autenticando...'
    }

    if (!user.value) {
        return navigateTo('/login')
    }
    const { data } = await supabase
        .from('auth_user')
        .select('role')
        .eq('email', user.value.email)

    if (!(data && data?.[0]?.role?.includes('clicampo'))) {
        return navigateTo('/logout')
    }

    const userIsAuthorizedOnRoute = isAuthorized(_to, data)
    if (!userIsAuthorizedOnRoute) {
        return navigateTo('/')
    }
})
