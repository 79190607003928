export const useSplashScreen = () => {
    const isSplashScreenVisible = useState('is-splash-screen-visible', () => true)
    const isOnLoginFlow = useState('is-logging-in', () => false)
    const splashIcon = useState('splash-icon', () => '🍎')
    const splashText = useState('splash-text', () => 'Estocaqui')

    const hideSplashScreen = () => {
        isSplashScreenVisible.value = false
    }

    return {
        hideSplashScreen,
        isSplashScreenVisible,
        isOnLoginFlow,
        splashIcon,
        splashText,
    }
}
