import node_modules__pnpm__64pinia_43nuxt_640_4_6_rollup_642_79_1_typescript_644_9_4_vue_643_2_45_node_modules__64pinia_nuxt_dist_runtime_plugin_vue3_mjs_64EU71BhQt from "/vercel/path0/node_modules/.pnpm/@pinia+nuxt@0.4.6_rollup@2.79.1_typescript@4.9.4_vue@3.2.45/node_modules/@pinia/nuxt/dist/runtime/plugin.vue3.mjs";
import _nuxt_components_plugin_mjs_KR1HBZs4kY from "/vercel/path0/.nuxt/components.plugin.mjs";
import node_modules__pnpm_nuxt_643_0_0_eslint_648_30_0_rollup_642_79_1_typescript_644_9_4_node_modules_nuxt_dist_head_runtime_lib_vueuse_head_plugin_mjs_AEwDHH5tgr from "/vercel/path0/node_modules/.pnpm/nuxt@3.0.0_eslint@8.30.0_rollup@2.79.1_typescript@4.9.4/node_modules/nuxt/dist/head/runtime/lib/vueuse-head.plugin.mjs";
import node_modules__pnpm_nuxt_643_0_0_eslint_648_30_0_rollup_642_79_1_typescript_644_9_4_node_modules_nuxt_dist_pages_runtime_router_mjs_2ZDPiG9KL2 from "/vercel/path0/node_modules/.pnpm/nuxt@3.0.0_eslint@8.30.0_rollup@2.79.1_typescript@4.9.4/node_modules/nuxt/dist/pages/runtime/router.mjs";
import _nuxt_motion_js_zfwFQdqfVT from "/vercel/path0/.nuxt/motion.js";
import node_modules__pnpm__64nuxtjs_43supabase_640_3_0_rollup_642_79_1_node_modules__64nuxtjs_supabase_dist_runtime_plugins_auth_redirect_mjs_MqK7jV5TAu from "/vercel/path0/node_modules/.pnpm/@nuxtjs+supabase@0.3.0_rollup@2.79.1/node_modules/@nuxtjs/supabase/dist/runtime/plugins/auth-redirect.mjs";
import node_modules__pnpm__64nuxtjs_43supabase_640_3_0_rollup_642_79_1_node_modules__64nuxtjs_supabase_dist_runtime_plugins_supabase_client_mjs_zHErnenHOJ from "/vercel/path0/node_modules/.pnpm/@nuxtjs+supabase@0.3.0_rollup@2.79.1/node_modules/@nuxtjs/supabase/dist/runtime/plugins/supabase.client.mjs";
import node_modules__pnpm__64nuxt_43image_edge_641_0_0_27840416_dc1ed65_rollup_642_79_1_node_modules__64nuxt_image_edge_dist_runtime_plugin_mjs_Ie4CU2S7IL from "/vercel/path0/node_modules/.pnpm/@nuxt+image-edge@1.0.0-27840416.dc1ed65_rollup@2.79.1/node_modules/@nuxt/image-edge/dist/runtime/plugin.mjs";
import _nuxt_unocss_mjs_MzCDxu9LMj from "/vercel/path0/.nuxt/unocss.mjs";
import node_modules__pnpm__64vueuse_43nuxt_649_9_0_nuxt_643_0_0_rollup_642_79_1_vue_643_2_45_node_modules__64vueuse_nuxt_ssr_plugin_mjs_ol5rb2DVNE from "/vercel/path0/node_modules/.pnpm/@vueuse+nuxt@9.9.0_nuxt@3.0.0_rollup@2.79.1_vue@3.2.45/node_modules/@vueuse/nuxt/ssr-plugin.mjs";
import node_modules__pnpm__64pinia_plugin_persistedstate_43nuxt_641_0_0__64pinia_43nuxt_640_4_6_pinia_642_0_20_rollup_642_79_1_node_modules__64pinia_plugin_persistedstate_nuxt_dist_runtime_plugin_mjs_bdJYrdrHch from "/vercel/path0/node_modules/.pnpm/@pinia-plugin-persistedstate+nuxt@1.0.0_@pinia+nuxt@0.4.6_pinia@2.0.20_rollup@2.79.1/node_modules/@pinia-plugin-persistedstate/nuxt/dist/runtime/plugin.mjs";
import plugins_floating_client_ts_8G5uQNntXG from "/vercel/path0/plugins/floating.client.ts";
import plugins_maska_ts_UHaKf2z1iQ from "/vercel/path0/plugins/maska.ts";
import plugins_sentry_client_ts_shVUlIjFLk from "/vercel/path0/plugins/sentry.client.ts";
import plugins_toastification_ts_xLadjaQwvu from "/vercel/path0/plugins/toastification.ts";
export default [
  node_modules__pnpm__64pinia_43nuxt_640_4_6_rollup_642_79_1_typescript_644_9_4_vue_643_2_45_node_modules__64pinia_nuxt_dist_runtime_plugin_vue3_mjs_64EU71BhQt,
  _nuxt_components_plugin_mjs_KR1HBZs4kY,
  node_modules__pnpm_nuxt_643_0_0_eslint_648_30_0_rollup_642_79_1_typescript_644_9_4_node_modules_nuxt_dist_head_runtime_lib_vueuse_head_plugin_mjs_AEwDHH5tgr,
  node_modules__pnpm_nuxt_643_0_0_eslint_648_30_0_rollup_642_79_1_typescript_644_9_4_node_modules_nuxt_dist_pages_runtime_router_mjs_2ZDPiG9KL2,
  _nuxt_motion_js_zfwFQdqfVT,
  node_modules__pnpm__64nuxtjs_43supabase_640_3_0_rollup_642_79_1_node_modules__64nuxtjs_supabase_dist_runtime_plugins_auth_redirect_mjs_MqK7jV5TAu,
  node_modules__pnpm__64nuxtjs_43supabase_640_3_0_rollup_642_79_1_node_modules__64nuxtjs_supabase_dist_runtime_plugins_supabase_client_mjs_zHErnenHOJ,
  node_modules__pnpm__64nuxt_43image_edge_641_0_0_27840416_dc1ed65_rollup_642_79_1_node_modules__64nuxt_image_edge_dist_runtime_plugin_mjs_Ie4CU2S7IL,
  _nuxt_unocss_mjs_MzCDxu9LMj,
  node_modules__pnpm__64vueuse_43nuxt_649_9_0_nuxt_643_0_0_rollup_642_79_1_vue_643_2_45_node_modules__64vueuse_nuxt_ssr_plugin_mjs_ol5rb2DVNE,
  node_modules__pnpm__64pinia_plugin_persistedstate_43nuxt_641_0_0__64pinia_43nuxt_640_4_6_pinia_642_0_20_rollup_642_79_1_node_modules__64pinia_plugin_persistedstate_nuxt_dist_runtime_plugin_mjs_bdJYrdrHch,
  plugins_floating_client_ts_8G5uQNntXG,
  plugins_maska_ts_UHaKf2z1iQ,
  plugins_sentry_client_ts_shVUlIjFLk,
  plugins_toastification_ts_xLadjaQwvu
]